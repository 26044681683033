import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				width: '50%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '70%',
				height: '500px'
			},
			config: {
				fields: {
					aptek_name: {},
					short_name: {}
				}
			}
		}
	}
}